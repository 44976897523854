import styled from '@emotion/styled';
import spinner from '../../images/loading_spinner.gif';

const MyPageLoadingRoot = styled.div((props) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center"
}));

const MyPageLoading = (props) => {

  const { children, style } = props;

  return (
    <MyPageLoadingRoot style={style}>
      <div style={{ width: "100%", textAlign: "center" }}>
	<img src={spinner} alt="Daten werden geladen" style={{ width: "100%", height: "auto", maxWidth: "100px" }}></img>
        <p>Daten werden geladen</p>
      </div>
    </MyPageLoadingRoot>
  );
}

export default MyPageLoading;
